import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const [owner, setOwner] = useState("");
  // const [reserver, setReserver] = useState("");
  const [totalSupply, settotalSupply] = useState(0);
  const [inputValue, setInputValue] = useState("");
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(``);
  const mintAmount = 1;

  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 0,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    SHOW_BACKGROUND: false,
  });

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  const checkOwner = async () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      try {
        let receipt = await blockchain.smartContract.methods.owner().call();
        receipt = receipt.toLowerCase();
        console.log(receipt);
        setOwner(receipt);
        dispatch(fetchData(blockchain.account));
      } catch (error) {
        console.error("Error while checking owner:", error);
      }
    }
  };

  const checktotalSupply = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
        .totalSupply()
        .call()
        .then((receipt) => {
          settotalSupply(receipt);
          dispatch(fetchData(blockchain.account));
        });
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const claimNFTs = async () => {
    let method = await blockchain.smartContract.methods.ownerMint(inputValue, mintAmount);
    setFeedback(`しばらくそのままお待ちください...`);
    setClaimingNft(true);
    try {
      let receipt = await method.send({
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        maxPriorityFeePerGas: "40000000000",
      });
      console.log(receipt);
      setFeedback(
        `ミントが完了しました`
      );
      setClaimingNft(false);
      checktotalSupply();
      dispatch(fetchData(blockchain.account));
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    } catch (err) {
      console.log(err);
      setFeedback("もう一度お試しください");
      setClaimingNft(false);
    }
  };

  useEffect(() => {
    getData();
    getConfig();
    checkOwner();
    checktotalSupply();
  }, []);

  useEffect(() => {
    getData();
    getConfig();
    checkOwner();
    checktotalSupply();
  }, [blockchain.account]);

  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: "0px" }}
        image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg.png" : null}
      >
        {/**
        <s.TextDescription style={{ position: 'fixed', width: '100%', fontSize: 26, textAlign: "left", backgroundColor: "rgba(0, 0, 0)" }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <s.StyledLink style={{ color: "#fff", margin: "8px 0px 0px 14px" }} href="">
              <s.TextDescription style={{ fontSize: "20px", paddingBottom: "6px" }}>Covered Records</s.TextDescription>
            </s.StyledLink>
            <div style={{ display: 'flex', alignItems: 'center', marginRight: "16px" }}>
              <a href="https://twitter.com/KedamaNft" target="_blank" rel="noopener" style={{ marginRight: "16px" }}>
                <img style={{ width: "18px", marginTop: "6px" }} src="/config/images/x.png" alt="X Icon"></img>
              </a>
              <a href="https://opensea.io/collection/covered-records-the-day-over-cooked-hokke-matic" target="_blank" rel="noopener">
                <img style={{ width: "23px", marginTop: "11px" }} src="/config/images/os.png" alt="OpenSea Icon"></img>
              </a>
            </div>
          </div>
        </s.TextDescription>
         */}

        <s.Container
          flex={2}
          jc={"center"}
          ai={"center"}
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            border: "none"
          }}
        >

          <img style={{ paddingTop: "0px", maxWidth: "200px" }} src="/config/images/jacket.png"></img>
          <s.TextTitle style={{ fontSize: "13px", margin: "4px 0px 6px" }}>- Nコレ特設サイト -</s.TextTitle>
          {Number(totalSupply) >= CONFIG.MAX_SUPPLY ? (
            <>
              <s.TextTitle>Sold out! Thank you!!</s.TextTitle>
            </>
          ) : (
            <>
              {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                <s.Container ai={"center"} jc={"center"}>
                  <s.StyledButton
                    style={{ fontWeight: "bold" }}
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(connect());
                      checkOwner();
                      getData();
                    }}
                  >
                    CONNECT
                  </s.StyledButton>

                  {blockchain.errorMsg !== "" ? (
                    <>
                      <s.TextDescription style={{ fontSize: "11px" }}>{blockchain.errorMsg}</s.TextDescription>
                    </>
                  ) : null}

                </s.Container>
              ) : (
                <>
                  {blockchain.account !== owner ? (
                    <>
                      <s.TextDescription style={{ fontSize: "15px" }}></s.TextDescription>
                      {/*<s.TextDescription style={{ color: "#999" }}>{owner}</s.TextDescription>*/}
                      {/*<s.TextDescription style={{ color: "#fff" }}>{blockchain.account}</s.TextDescription>*/}
                    </>
                  ) : (
                    <>
                      <input
                        type="text"
                        value={inputValue}
                        onChange={handleInputChange}
                        placeholder="送付先アドレスを入力"
                        style={{ margin: "8px 0px 12px", padding: "4px", width: "240px" }}
                      />
                      <s.Container ai={"center"} jc={"center"} fd={"row"}>
                        <s.StyledButton
                          style={{ fontWeight: "bold" }}
                          disabled={claimingNft ? 1 : 0}
                          onClick={(e) => {
                            e.preventDefault();
                            claimNFTs();
                            getData();
                          }}
                        >
                          {claimingNft ? "Mint now" : "Mint"}
                        </s.StyledButton>
                      </s.Container>
                      <s.TextDescription style={{ fontSize: "13px" }}>
                        {feedback}
                      </s.TextDescription>
                    </>
                  )}
                </>
              )}
            </>
          )}

        </s.Container>
      </s.Container>
    </s.Screen>
  );
}
export default App;